import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import './index.css'
import MGrillLogo from '../components/MGrillLogo'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div> */}
    {/* <h1>Hi people</h1> */}
    <h2
      style={{
        fontWeight: "900",
        // textTransform: "uppercase",
      }}
      >Locations</h2>

    <article className="location"
      style={{
        background: "#fff3c1",
        padding: "1.5em 0.8em",
        borderRadius: "0.5em",
      }}
      >

      <header className="location-header">
        <figure
          style={{
            width: "50px",
          }}
          >
          <MGrillLogo />
        </figure>
        <h3>Pasadena</h3>
      </header>
      <p className="address">105 N Hill Ave #101</p>
      <nav style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "baseline"
      }}>

        <a
          style={{
            background: "#df0018",
            display: "inline-block",
            color: "white",
            fontWeight: 900,
            borderRadius: "0.4em",
            padding: "0.3em 0.4em",
            textDecoration: 'none'
          }}
          className="order-button" href="https://mgrillla.com/?from=mlocal">Order Delivery & Pickup</a>

        <a href="https://mgrillla.com/?from=mlocal">Website</a>
        <a href="https://mgrillla.com/menu?from=mlocal">Menu</a>
      </nav>

    </article>

    <article className="location"
      style={{
        background: "#fff3c1",
        padding: "1.5em 0.8em",
        margin: "1em 0",
        borderRadius: "0.5em",

      }}
      >
      <h3>More Locations Coming Soon...</h3>
    </article>

    {/* <p>Now go build something great.</p> */}
    {/* <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default IndexPage
